import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import cargate from "../images/Cargate.webp";
import carrera from "../images/Carrera.webp";
import axtone from "../images/AxtoneAcademy.webp";
import sae from "../images/SAE.webp";
import { Helmet } from "react-helmet";

const Portfolio = () => {
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => {
    console.log("Modal wird geöffnet");
    setModalContent(content);
  };

  const closeModal = () => {
    console.log("Modal wird geschlossen");
    setModalContent(null);
  };

  const projects = [
    {
      image: cargate,
      alt: "Cargate Project...",
      title: "Cargate Project",
      description:
        "At Code Icons, we're proud to introduce Cargate.ch, featuring a customized car configurator for end consumers. This innovative platform allows users to personalize and order vehicles online, with integrated financing options for a seamless and convenient experience. Elevate your car-buying journey with our tailored web solutions.",
      githubLink: "https://www.cargate.ch",
    },
    {
      image: carrera,
      alt: "Carrera Project...",
      title: "Carrera Project",
      description:
        "Explore the dynamic world of luxury eyewear with our project at us.carreraworld.com. We've crafted a sophisticated online experience, seamlessly blending style and functionality. Discover an extensive collection of premium eyewear, showcased on a user-friendly platform designed to enhance your shopping journey. Elevate your online eyewear shopping with Code Icons.",
      githubLink: "https://us.carreraworld.com",
    },
    {
      image: axtone,
      alt: "Axtone Project...",
      title: "Axtone Project",
      description:
        "Dive into the realm of music education with Axtone Academy's innovative platform at www.axtoneacademy.com. Our team at Code Icons has collaborated to create a cutting-edge website tailored for music enthusiasts. Explore a rich array of courses, connect with top industry professionals, and embark on a transformative musical journey. Elevate your learning experience with Code Icons – where technology meets musical excellence.",
      githubLink: "https://www.axtoneacademy.com",
    },
    {
      image: sae,
      alt: "SAE Switzerland Project......",
      title: "SAE Switzerland Project",
      description:
        "Explore the dynamic world of media education at www.sae.edu/che/. Code Icons proudly collaborated with SAE Institute to develop an interactive and user-friendly website. Navigate through a seamless platform designed to empower students in their creative pursuits. Discover courses, connect with industry experts, and unlock your creative potential. Trust Code Icons to bring innovation to education, where technology meets the future of media learning.",
      githubLink: "https://www.sae.edu/che/",
    },
  ];

  return (
    <div
      className="portfolio-wrapper"
      style={{ backgroundColor: "#f4f4f4", position: "relative", zIndex: 3 }}
    >
      <Helmet>
        <meta
          name="description"
          content="Explore Code Icons' portfolio showcasing innovative projects in web development, app development, and blockchain solutions. Elevate your digital presence with our tailored solutions."
        />
      </Helmet>
      <div className="container mx-auto">
        <h1 className="text-uppercase text-center py-5">portfolio</h1>
        <div className="row text-center">
          {projects.map((project, index) => (
            <div className="col-12 col-sm-6 col-md-6 col-lg-3" key={index}>
              <div
                className="portfolio-image-box"
                onClick={() =>
                  openModal(renderModalContent(project, closeModal))
                }
              >
                <div className="portfolio-image-container">
                  <img
                    className="portfolio-image"
                    src={project.image}
                    alt={project.alt}
                  />
                  <div className="overlay"></div>
                  <FontAwesomeIcon
                    className="portfolio-icon"
                    icon={faSearchPlus}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {modalContent && (
        <div className="modal" style={{ zIndex: 3 }} onClick={closeModal}>
          <div className="modal-content" style={{ zIndex: 10000 }}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            {modalContent}
          </div>
        </div>
      )}
    </div>
  );

  // Funktion zum Rendern des Modalinhalts für jedes Projekt
  function renderModalContent(project, onClose) {
    return (
      <>
        <img
          className="portfolio-image-popupbox"
          src={project.image}
          alt={project.alt}
        />
        <p>{project.description}</p>
        <b>Website:</b>{" "}
        <a
          className="hyper-link"
          onClick={() => window.open(project.githubLink)}
        >
          {project.githubLink}
        </a>
      </>
    );
  }
};

export default Portfolio;
