import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MyNavbar from "./components/MyNavbar";
import Header from "./components/Header";
import ParticleComponent from "./components/ParticleComponent";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";
import { PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";
import Testimonials from "./components/Testimonials";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Code Icons - Transforming ideas into digital excellence. We offer web design, app development, blockchain solutions, and digital marketing services. Explore our portfolio and client testimonials."
        />
        {/* Add other global meta tags as needed */}
      </Helmet>
      <MyNavbar />
      <Header />
      <Helmet>
        <title>Code Icons - Where Ideas Code the Future</title>
      </Helmet>
      {/*<ParticleComponent />*/}
      <AboutUs />
      <Helmet>
        <title>About Us - Code Icons</title>
        <meta
          name="description"
          content="Learn about Code Icons - a team of experts specializing in web design, app development, blockchain solutions, and digital marketing. Discover our vision and commitment to digital excellence."
        />
      </Helmet>
      <Services />
      <Helmet>
        <title>Services - Code Icons</title>
        <meta
          name="description"
          content="Explore Code Icons' services, including web development, app development, blockchain solutions, and Google Ads expertise. Elevate your digital presence with our tailored solutions."
        />
      </Helmet>
      <Experience />
      <Helmet>
        <title>Experience - Code Icons</title>
        <meta
          name="description"
          content="Explore Code Icons' journey and experience in crafting web designs, mobile apps, blockchain solutions, and successful digital marketing campaigns. Discover our commitment to excellence."
        />
      </Helmet>
      <Portfolio />
      <Helmet>
        <title>Portfolio - Code Icons</title>
        <meta
          name="description"
          content="View Code Icons' portfolio featuring projects like Cargate, Carrera, Axtone Academy, and SAE Switzerland. Explore our web designs, app development, and blockchain solutions."
        />
      </Helmet>
      <PopupboxContainer />
      <Testimonials />
      <Helmet>
        <title>Client Testimonials - Code Icons</title>
        <meta
          name="description"
          content="Read testimonials from Code Icons' happy clients. Discover their experiences with our web design, app development, and digital marketing services. Join our satisfied clients."
        />
      </Helmet>
      <Contacts />
      <Helmet>
        <title>Contact Us - Code Icons</title>
        <meta
          name="description"
          content="Connect with Code Icons to transform your vision into a digital masterpiece. Share your project details and let's craft excellence together. Reach out for web design, app development, and more."
        />
      </Helmet>
      <Footer />
    </>
  );
}

export default App;
