import React from "react";
import { TypeAnimation } from "react-type-animation";
import ParticleComponent from "./ParticleComponent";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet";

const Header = ({ showParticles }) => {
  console.log("Header - showParticles:", showParticles);

  return (
    <div className="header-wrapper" style={{ position: "relative", zIndex: 1 }}>
      <Helmet>
        <title>Code Icons - Crafting the Future of Web Development</title>
        <meta
          name="description"
          content="Welcome to Code Icons, where ideas code the future. Explore our services, including web design, web development, app development, blockchain solutions, Google Ads, and SEO excellence. Contact us for transformative digital experiences."
        />
      </Helmet>
      <div className="main-info">
        <h1>Where Ideas Code the Future</h1>
        <TypeAnimation
          className="typed-text"
          sequence={[
            // Same substring at the start will only be typed out once, initially
            "Crafting Stunning Web Designs",
            1000, // wait 1s before replacing "Mice" with "Hamsters"
            "Expert Web Development",
            1000,
            "Seamless App Experiences",
            1000,
            "Future-Ready Blockchain Solutions",
            1000,
            "Boost Visibility with Google Ads",
            1000,
            "Stand Out with SEO Excellence",
            1000,
          ]}
          wrapper="span"
          speed={40}
          style={{ fontSize: "2em", display: "inline-block" }}
          repeat={Infinity}
        />

        {/*<Typed
          className="typed-text"
          strings={[
            "Web Design",
            "Web Development",
            "App Development",
            "Blockchain Development",
            "Google Ads",
            "SEO",
          ]}
          typeSpeed={40}
          backSpeed={60}
          loop
        />*/}
        {/*<a
          href="contact"
          className="btn-main-offer"
          style={{
            textDecoration: "none",
            backgroundColor: "var(--primary-hover-red)",
            color: "var(--primary-white)",
            transition: "0.2s ease-in-out",
          }}
        >
          contact us
        </a>*/}
        <Link
          style={{
            textDecoration: "none",
            backgroundColor: "var(--primary-hover-red)",
            color: "var(--primary-white)",
            transition: "0.2s ease-in-out",
            cursor: "pointer",
          }}
          activeClass="active"
          to="contacts"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="btn-main-offer"
        >
          contact us
        </Link>
      </div>
      <ParticleComponent showParticles={showParticles} />
    </div>
  );
};

export default Header;
