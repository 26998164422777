import React from "react";
import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// AVATARS IMPORT
import avatar1 from "../images/avatars/avatar-1.png";
import avatar2 from "../images/avatars/avatar-2.png";
import avatar3 from "../images/avatars/avatar-3.png";
import avatar4 from "../images/avatars/avatar-4.png";

const TestimonialsCarousel = () => {
  return (
    <>
      <Helmet>
        <title>Client Testimonials - Code Icons</title>
        <meta
          name="description"
          content="Explore what clients are saying about Code Icons. Read testimonials highlighting our expertise in web design, app development, digital marketing, and blockchain solutions. Discover why clients trust Code Icons for exceptional results."
        />
      </Helmet>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={3000}
      >
        <div>
          <img src={avatar1} alt="Emma Larsen" />
          <div className="myCarousel">
            <h3>Emma Larsen</h3>
            <p>
              "Working with Code Icons was a game-changer for my online
              presence. Their team's creativity in web design brought my vision
              to life. The user-friendly website they crafted has truly set my
              brand apart in the digital landscape. I highly recommend their
              expertise!"
            </p>
          </div>
        </div>
        <div>
          <img src={avatar2} alt="Alex Weber" />
          <div className="myCarousel">
            <h3>Alex Weber</h3>
            <p>
              "Code Icons exceeded my expectations in app development. As an iOS
              user, I needed an app that not only met but surpassed my
              expectations. Their team of developers, especially the Swift
              masters, delivered a seamless and feature-rich experience. Kudos
              to Code Icons for their exceptional work!"
            </p>
          </div>
        </div>
        <div>
          <img src={avatar3} alt="Olivia Meier" />
          <div className="myCarousel">
            <h3>Olvia Meier</h3>
            <p>
              "Choosing Code Icons for digital marketing was the best decision
              for my business. Their strategic approach, from compelling content
              creation to effective SEO techniques, significantly boosted my
              online visibility. Code Icons is a reliable partner for anyone
              seeking success in the digital realm."
            </p>
          </div>
        </div>
        <div>
          <img src={avatar4} alt="Patrick Schneider" />
          <div className="myCarousel">
            <h3>Patrick Schneider</h3>
            <p>
              "Venturing into blockchain with Code Icons was a decision I'll
              never regret. Their expertise in Ethereum smart contracts and
              decentralized applications is unmatched. They ensured the security
              and innovation of my project. Code Icons is the go-to for anyone
              exploring the potential of blockchain!"
            </p>
          </div>
        </div>
      </Carousel>
    </>
  );
};

export default TestimonialsCarousel;
