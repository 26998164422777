import React from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faFileCode, faMobile } from "@fortawesome/free-solid-svg-icons";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";

const Services = () => {
  return (
    <div className="services" style={{ position: "relative", zIndex: 2 }}>
      <Helmet>
        <title>Our Services - Code Icons</title>
        <meta
          name="description"
          content="Explore Code Icons' comprehensive range of services, including expert web development, masterful app development, blockchain solutions, and powerful Google Ads strategies. Unlock digital excellence with tailored solutions for success."
        />
      </Helmet>
      <h1 className="py-5">our services</h1>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon className="icon" icon={faFileCode} size="2x" />
              </div>
              <h3>Web Development</h3>
              <p>
                Achieve digital excellence: Expert web development captivates,
                creating seamless experiences. Crafted for success, tailored for
                you.
              </p>
            </div>
          </div>
          {/* - */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon className="icon" icon={faMobile} size="2x" />
              </div>
              <h3>App Development</h3>
              <p>
                Elevate your brand: Expert app development for iOS and Android,
                blending innovation with user-friendly features. Your digital
                success, our expertise.
              </p>
            </div>
          </div>
          {/* - */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon className="icon" icon={faBitcoin} size="2x" />
              </div>
              <h3>Blockchain</h3>
              <p>
                Transform with Code Icons: Blockchain expertise unlocks
                innovation. Smart contracts, tokens, and DApps for your
                venture's success.
              </p>
            </div>
          </div>
          {/* - */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div className="circle">
                <FontAwesomeIcon className="icon" icon={faGoogle} size="2x" />
              </div>
              <h3>Google Ads</h3>
              <p>
                Crafting powerful online presence: Code Icons' Google Ads
                elevate visibility, drive traffic, and ensure you stand out in
                the digital realm.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
