import React from "react";
import { Helmet } from "react-helmet";
import author from "../me.jpg";
import { Element } from "react-scroll";

const AboutUs = () => {
  return (
    <Element name="about">
      <div
        className="container-fluid py-5 px-5"
        style={{ backgroundColor: "white", position: "relative", zIndex: 2 }}
      >
        <Helmet>
          <title>About Us - Code Icons Agency</title>
          <meta
            name="description"
            content="Premier web agency in Zurich specializing in web development, mobile apps, blockchain, and digital marketing."
          />
        </Helmet>
        {/* <ParticleComponent showParticles={showParticles} /> */}
        <div className="row">
          <div className="col-lg-6 col-xm-12">
            <div className="photo-wrap mb-6">
              <img className="profile-img" src={author} alt="author..." />
            </div>
          </div>
          <div className="col-lg-6 col-xm-12">
            <h1 className="about-heading">about us</h1>
            <p>
              Welcome to Code Icons, your premier web agency located in the
              vibrant city of Zurich! At Code Icons, our dedicated team is
              fueled by a passion for creating exceptional digital experiences
              tailored just for you. Step into the essence of our expertise:
              <br />
              <br />
              <strong>Crafting Unique Digital Experiences:</strong> In the
              ever-evolving digital landscape, we specialize in designing
              visually stunning and user-friendly websites. We focus on creating
              a seamless online presence that captivates your audience and sets
              you apart from the competition.
              <br />
              <br />
              <strong>Empowering Your Mobile Presence:</strong> Whether you're
              targeting the iOS or Android market, our adept developers
              specialize in crafting feature-rich applications. Our iOS
              developers master Swift programming, ensuring your app not only
              meets but exceeds user expectations. Meanwhile, our Android Kotlin
              developers seamlessly create experiences from the ground up,
              blending innovation with user-friendly functionalities.
              <br />
              <br />
              <strong>Pioneering the Future with Blockchain:</strong> Unlock the
              potential of blockchain technology with us. Our expertise covers
              the fundamentals, Ethereum smart contracts, tokens, and
              decentralized applications (DApps). Navigating the intricacies of
              blockchain architecture, we prioritize privacy and security,
              ensuring your ventures into the future are both innovative and
              safeguarded.
              <br />
              <br />
              <strong>Elevating Your Online Presence:</strong> In the vast
              digital landscape, our digital marketing experts are your
              strategic allies. We don't just run campaigns; we architect
              success. From crafting compelling content to managing engaging
              social media campaigns, we specialize in SEO/SEM excellence. Our
              SEO skills include thorough audits, strategic keyword lists, and
              effective link-building strategies, boosting your online
              visibility and ensuring you stand out in the competitive digital
              realm.
              <br />
              <br />
              <strong>Why Choose Code Icons?</strong> At Code Icons, we breathe
              life into every project. From the aesthetics of frontend design to
              the intricacies of backend development, from pioneering blockchain
              innovations to mastering the art of digital marketing, we are
              committed to delivering excellence. Your success is our priority,
              and we're here to transform your digital presence into something
              extraordinary.
              <br />
              <br />
              Embark on a transformative journey with Code Icons, where
              innovation converges with expertise, and your digital aspirations
              become a vibrant reality. Let's craft your unique story in the
              digital world!
            </p>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default AboutUs;
