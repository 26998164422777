import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";
import { Helmet } from "react-helmet";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <div
      className="footer"
      style={{
        backgroundColor: "black",
        position: "relative",
        zIndex: 2,
      }}
    >
      <Helmet>
        <title>Code Icons - FullStack Developer | Zürich, Switzerland</title>
        <meta
          name="description"
          content="Connect with Code Icons, your premier web agency located in Zürich, Switzerland. Explore our services, experience, and portfolio. Contact us for transformative digital experiences."
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="d-flex">
              <p>
                Code Icons GmbH<br></br>Zollstrasse 61<br></br> 8005 Zürich
              </p>
            </div>
            <div className="d-flex">
              <a href="tel:+41-(0)44-515-59-85">+41(0)44-515-59-85</a>
            </div>
            <div className="d-flex">
              <p>info@codeicons.ch</p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-2 col-sm-6"
            style={{ "padding-top": "1rem" }}
          >
            <div className="row">
              {/* <div className="col">
                <FooterNavItem itemName={"header-wrapper"}>Home</FooterNavItem>

                <br />
                <FooterNavItem itemName={"about"}>About Us</FooterNavItem>

                <br />
                <FooterNavItem itemName={"services"}>Services</FooterNavItem>
              </div>
              <div className="col">
                <FooterNavItem itemName={"experience"}>
                  Experience
                </FooterNavItem>
                <br />

                <FooterNavItem itemName={"portfolio-wrapper"}>
                  Portfolio
                </FooterNavItem>
                <br />

                <FooterNavItem itemName={"contacts"}>Contact</FooterNavItem>
              </div> */}
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-6 align-items-center"
            style={{ "padding-top": "2rem" }}
          >
            <div className="d-flex justify-content-center">
              <FacebookShareButton
                url={"www.codeicons.ch"}
                quote={"FullStack Developer"}
                hashtag="#javascript"
              >
                <FacebookIcon className="mx-3" size={36} />
              </FacebookShareButton>
              <TwitterShareButton
                url={"www.codeicons.ch"}
                quote={"FullStack Developer"}
                hashtag="#javascript"
              >
                <TwitterIcon className="mx-3" size={36} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={"www.codeicons.ch"}
                quote={"FullStack Developer"}
                hashtag="#javascript"
              >
                <LinkedinIcon className="mx-3" size={36} />
              </LinkedinShareButton>
              <RedditShareButton
                url={"www.codeicons.ch"}
                quote={"FullStack Developer"}
                hashtag="#javascript"
              >
                <RedditIcon className="mx-3" size={36} />
              </RedditShareButton>
            </div>
            <div className="pt-3 text-center">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;Code Icons | All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FooterNavItem = ({ itemName, children }) => (
  <Link
    to={itemName}
    spy={true}
    smooth={true}
    offset={-70}
    duration={500}
    className={"footer-nav"}
  >
    {children}
  </Link>
);

export default Footer;
