import React from "react";
import { Helmet } from "react-helmet";

const Experience = () => {
  return (
    <div
      className="experience"
      style={{ backgroundColor: "white", position: "relative", zIndex: 2 }}
    >
      <Helmet>
        <title>Experience - Code Icons Agency</title>
        <meta
          name="description"
          content="Explore the journey of Code Icons Agency's expertise, from crafting web and mobile applications to pioneering blockchain and leading digital marketing campaigns."
        />
      </Helmet>
      <div className="d-flex justify-content-center my-5">
        <h1>experience</h1>
      </div>
      <div className="container experience-wrapper">
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2017-2018</h3>
            <p>
              Played a pivotal role in crafting numerous internal applications
              for well-known companies, showcasing expertise in web design and
              development. Skills included creating visually appealing
              interfaces with HTML, CSS, and responsive design.
            </p>
          </div>
        </div>
        {/* - */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2018-2019</h3>
            <p>
              Advanced skills in mobile app development during this period.
              Contributed to feature-rich applications for iOS and Android,
              mastering Swift programming and Kotlin development. Ensured
              seamless user experiences from basic architecture to advanced
              functionalities.
            </p>
          </div>
        </div>
        {/* - */}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2019-2020</h3>
            <p>
              Pioneered blockchain development, delving into fundamentals,
              Ethereum smart contracts, tokens, and decentralized applications
              (DApps). Navigated intricacies of blockchain architecture,
              prioritizing privacy and security for future-focused ventures.
            </p>
          </div>
        </div>
        {/* - */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2020-2021</h3>
            <p>
              Led digital marketing campaigns for online success. Specialized in
              SEO/SEM excellence, conducting thorough audits, crafting strategic
              keyword lists, and implementing effective link-building
              strategies. Boosted online visibility for clients in the
              competitive digital realm.
            </p>
          </div>
        </div>
        {/* - */}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2021-2022</h3>
            <p>
              Continued commitment to excellence in web and app development,
              integrating the latest technologies. Expanded expertise in Google
              Ads, ensuring businesses stay ahead in the competitive digital
              landscape. Skills encompassed frontend and backend development for
              comprehensive digital solutions.
            </p>
          </div>
        </div>
        {/* - */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2022-2023</h3>
            <p>
              Ongoing dedication to delivering exceptional digital experiences.
              Collaborated on projects that transformed client aspirations into
              vibrant digital realities. Maintained a strong focus on
              innovation, expertise, and continuous growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
