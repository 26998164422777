import React from "react";
import { Helmet } from "react-helmet";
import TestimonialsCarousel from "./TestimonialsCarousel";

const Testimonials = () => {
  return (
    <div
      className="testimonials"
      style={{ backgroundColor: "white", position: "relative", zIndex: 2 }}
    >
      <Helmet>
        <title>Happy Clients Testimonials - Code Icons</title>
        <meta
          name="description"
          content="Discover what our happy clients are saying about Code Icons. Read testimonials showcasing our commitment to delivering exceptional digital solutions, innovative web and app development, and top-notch client satisfaction."
        />
      </Helmet>
      <h1>our happy clients</h1>
      <div className="container">
        <div className="testimonials-content">
          <TestimonialsCarousel />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
