import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../logo.png";
import { Link } from "react-scroll";
import "../App.css";
// REACT FONTAWESOME IMPORTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const MyNavbar = () => {
  return <NavWrapper />;
};

{
  /*const MyNavbar = () => {
const [selectedNavItem, setSelectedNavItem] = useState(null); */
}

const NavWrapper = () => {
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      <Helmet>
        <title>Code Icons - Crafting Digital Excellence</title>
        <meta
          name="description"
          content="Explore Code Icons - Your Partner for Web Design, Web Development, App Development, Blockchain Solutions, Google Ads, and SEO Excellence. Navigate through our Home, About Us, Services, Experience, Portfolio, and Contact sections."
        />
      </Helmet>
      <Navbar
        bg="dark"
        expand="lg"
        variant="dark"
        fixed="top"
        expanded={isNavOpen}
      >
        <Navbar.Brand href="#">
          <img className="logo" src={logo} alt="logo..." />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleNavToggle}
        >
          <FontAwesomeIcon icon={faBars} style={{ color: "white" }} />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <NavItem
              itemName="header-wrapper"
              selectedNavItem={selectedNavItem}
              onClick={() => {
                setSelectedNavItem("header-wrapper");
                handleNavToggle();
              }}
            >
              Home
            </NavItem>
            <NavItem
              itemName="about"
              selectedNavItem={selectedNavItem}
              onClick={() => {
                setSelectedNavItem("about");
                handleNavToggle();
              }}
            >
              About Us
            </NavItem>
            <NavItem
              itemName="services"
              selectedNavItem={selectedNavItem}
              onClick={() => {
                setSelectedNavItem("services");
                handleNavToggle();
              }}
            >
              Services
            </NavItem>
            <NavItem
              itemName="experience"
              selectedNavItem={selectedNavItem}
              onClick={() => {
                setSelectedNavItem("experience");
                handleNavToggle();
              }}
            >
              Experience
            </NavItem>
            <NavItem
              itemName="portfolio-wrapper"
              selectedNavItem={selectedNavItem}
              onClick={() => {
                setSelectedNavItem("portfolio-wrapper");
                handleNavToggle();
              }}
            >
              Portfolio
            </NavItem>
            <NavItem
              itemName="contacts"
              selectedNavItem={selectedNavItem}
              onClick={() => {
                setSelectedNavItem("contacts");
                handleNavToggle();
              }}
            >
              Contact
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

const NavItem = ({ itemName, selectedNavItem, onClick, children }) => (
  <Link
    activeClass="active"
    to={itemName}
    spy={true}
    smooth={true}
    offset={-70}
    duration={500}
    className={selectedNavItem === itemName ? "nav-link active" : "nav-link"}
    onClick={() => onClick(itemName)}
  >
    {children}
    {selectedNavItem === itemName && <div className="underline"></div>}
  </Link>
);

export default MyNavbar;
