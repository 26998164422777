import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";

const Contacts = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [successMessage, setSuccessMessage] = useState(null);

  const serviceID = "service_ID";
  const templateID = "template_ID";
  const publicKey = "0VbqTgUCawhua6eJu";

  const sendEmail = (data) => {
    const templateParams = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      subject: data.subject,
      description: data.description,
    };

    emailjs.send(serviceID, templateID, templateParams, publicKey).then(
      (result) => {
        console.log(result.text);
        setSuccessMessage(
          "Form submitted successfully! I'll get back to you soon."
        );
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div
      className="contacts"
      style={{
        backgroundColor: "rgb(52, 58, 64)",
        position: "relative",
        zIndex: 2,
      }}
    >
      <Helmet>
        <title>Contact Us - Code Icons Agency</title>
        <meta
          name="description"
          content="Connect with Code Icons Agency to transform your vision into a digital masterpiece. Share your project details, and let's craft excellence together."
        />
      </Helmet>
      <div className="text-center">
        <h1>contact us</h1>
        <p>
          Ready to transform your vision into a digital masterpiece? Share your
          project details, and let's connect to craft excellence together.
        </p>
      </div>
      {successMessage && (
        <div className="text-center success-message">
          <p>{successMessage}</p>
        </div>
      )}
      <div className="container">
        <form onSubmit={handleSubmit(sendEmail)}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              {/* NAME INPUT */}
              <div className="text-center">
                <input
                  id="name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                  <p className="error-message">{errors.name.message}</p>
                )}
                <div className="line"></div>
              </div>
              {/* PHONE INPUT */}
              <div className="text-center">
                <input
                  id="phone"
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  {...register("phone", {
                    required: "Please add your phone number",
                  })}
                />
                {errors.phone && (
                  <p className="error-message">{errors.phone.message}</p>
                )}
                <div className="line"></div>
              </div>
              {/* EMAIL INPUT */}
              <div className="text-center">
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  {...register("email", {
                    required: "Please provide your email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email && (
                  <p className="error-message">{errors.email.message}</p>
                )}
                <div className="line"></div>
              </div>
              {/* SUBJECT INPUT */}
              <div className="text-center">
                <input
                  id="subject"
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  {...register("subject", {
                    required: "Oops, you forgot to add the subject",
                  })}
                />
                {errors.subject && (
                  <p className="error-message">{errors.subject.message}</p>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              {/* DESCRIPTION */}
              <div className="text-center">
                <textarea
                  id="description"
                  className="form-control"
                  placeholder="Please describe shortly your project..."
                  {...register("description", {
                    required: "Please describe shortly your project needs...",
                  })}
                ></textarea>
                {errors.description && (
                  <p className="error-message">{errors.description.message}</p>
                )}
                <div className="line"></div>
              </div>
              <button className="btn-main-offer contact-btn" type="submit">
                contact me
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contacts;
